import React, {useEffect} from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Auth} from "aws-amplify";
import {navigate} from "gatsby";
import WelcomeMessage from "../components/welcome-message";

const Welcome = () => {

    useEffect(async () => {
        try {
            const user = await Auth.currentSession();
        } catch (e) {
            navigate("/");
        }
    }, []);

    return (
        <Layout pageTitle="Welcome">
            <Seo title="Welcome" />
            <WelcomeMessage />
        </Layout>
    )
}

export default Welcome
