import React from 'react';
import BlockTitle from "./block-title";
import {Container} from "react-bootstrap";
import logoImage from "../assets/images/logo.png";

const WelcomeMessage = () => {
    return (
        <section className="subscribe-style-one center-children-horizontal-vertical">
            <Container>
                <BlockTitle
                    textAlign="center"
                    image={logoImage}
                    title={``}
                    showImage={true}
                />
                <h5 className="text-center">
                    Welcome to Fanster! You'll get an email within a few hours when your profile will be ready and you'll start receiving your first suggestions! Stay tuned!
                </h5>
            </Container>
        </section>
    );
};

export default WelcomeMessage
